import React from 'react';
import './Greeting.css';
import YourGif from '../Assets/bar_1.gif'; // import your gif

const Greeting = () => {
    return (
        <div className="greeting">
            <h2 className="greeting__subtitle">Hi there!</h2>
            <h1 className="greeting__title">Welcome to Advanced Cardiovascular Associates</h1>
            <img src={YourGif} alt="Your Gif" className="barGif"/>  {/* Display gif */}
        </div>
    );
};

export default Greeting;
