import React from 'react';
import './StepThree.css';
import { MdLocationOn, MdLocalPhone } from 'react-icons/md';

function StepThree() {
    const addresses = [
        {
            name: '22 Mile & Schoenherr',
            address: '49050 Schoenherr Rd #100, Shelby Twp, MI 48315',
            addressLink: 'https://goo.gl/maps/cvASVW7njMN87jbQ6',
            phoneNumber: 'Tel: +1 (586) 566-7870',
        },
        {
            name: 'Mound',
            address: '44850 Mound Rd, Sterling Heights, MI 48314',
            addressLink: 'https://goo.gl/maps/TuB8a9FCPmit8hfb8',
            phoneNumber: 'Tel: +1 (586) 731-7000',
        },
        {
            name: '13 Mile',
            address: '14061 13 Mile Rd Suite #3, Warren, MI 48088',
            addressLink: 'https://maps.app.goo.gl/1F43VzMe9zjuxwhy5',
            phoneNumber: 'Tel: +1 (586) 566-7870',
        },
    ];

    return (
        <div className="step3-container">
            {addresses.map((location, i) => (
                <div key={i} className="location-box">
                    <h2>{location.name}</h2>
                    <div className="icon-text">
                        <MdLocationOn className="icon" />
                        <p><a href={location.addressLink} target="_blank" rel="noopener noreferrer">{location.address}</a></p>
                    </div>
                    <div className="icon-text">
                        <MdLocalPhone className="icon" />
                        <p><a href={`tel:${location.phoneNumber.replace(/\D/g, '')}`}>{location.phoneNumber}</a></p>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default StepThree;
