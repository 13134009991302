import React, { useEffect, useRef, useState } from 'react';
import { Box, Text, Button, Link } from "@chakra-ui/react";
import background from '../Assets/background.jpg';

const Logos2 = () => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const currentRef = ref.current;  // Make a local copy of ref.current
  
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      {
        threshold: 0.1,
      }
    );
  
    if (currentRef) {
      observer.observe(currentRef);  // Use the local copy here
    }
  
    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);  // Use the local copy here
      }
    };
  }, []); 

  return (
    <Box
      w="100%"
      display="flex"
      justifyContent="center"
      bgImage={`url(${background})`}
      bgAttachment="fixed"
      bgPosition="center"
      bgSize="cover"
      bgRepeat="no-repeat"
      p={["10px", "20px", "30px"]}
    >
      <Box
        p="100px 0"
        w="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap="5px"
        ref={ref}
        opacity={isVisible ? 1 : 0}
        transition="opacity 2s ease-out"
      >
        <Text as="h2" fontSize={["30px", "40px", "60px"]} color="white" fontWeight="bold">
          Want To Join Our Team?
        </Text>
        <Text fontSize="24px" color="white" mt="10px" mb="20px">
          Join our dedicated cardiovascular team and help transform lives every day.
        </Text>
        <Link href="mailto:acaoffice1@gmail.com?subject=Inquiry%20About%20Working%20With%20You">
          <Button
            py={["8", "10", "12"]}
            px={["10", "12", "16"]}
            fontSize={["20px", "25px", "30px"]}
            bg="#f09797"
            color="white"
            borderRadius="25px"
            _hover={{ bg: "#db8080", transform: "scale(1.1)" }}
          >
            Send Us A Message
          </Button>
        </Link>
      </Box>
    </Box>
  );
};

export default Logos2;
