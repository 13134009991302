import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import './StepOne.css';
import axios from 'axios';

export default function StepOne() {
  const { register, handleSubmit, getValues, reset, formState: { errors } } = useForm();
  const [isSubmitted, setIsSubmitted] = useState(false);

  const onSubmit = async () => {
    const data = getValues();
    console.log("Submitting form with data:", data);

    try {
      const response = await axios.post('/api/send-email', {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        visitReason: data.visitReason,
        choiceReason: data.choiceReason
      });

      if (response.status === 200) {
        console.log('Email sent successfully');
        setIsSubmitted(true);
        reset();
      }
    } catch (error) {
      console.log('Error sending email', error);
    }
  };

  return (
    <div className="form-container">
      <div className="custom-form">
        {isSubmitted ? (
          <h2 className="form-title">Submitted!</h2>
        ) : (
          <>
            <h2 className="form-title">STEP ONE</h2>
            <p className="form-description">We would like to know you better. Please provide us with your answers to the following questions.</p>

            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-row">
                <input className="form-input half-width" {...register("firstName", { required: true })} placeholder="First Name" />
                {errors.firstName && <p className="error-message">This field is required</p>}

                <input className="form-input half-width" {...register("lastName", { required: true })} placeholder="Last Name" />
                {errors.lastName && <p className="error-message">This field is required</p>}
              </div>

              <input className="form-input" {...register("email", { required: true, pattern: /^\S+@\S+$/i })} placeholder="Email Address" />
              {errors.email && <p className="error-message">This field is required</p>}

              <textarea className="form-input" {...register("visitReason", { required: true })} placeholder="The primary reason for my visit is to discuss..."></textarea>
              {errors.visitReason && <p className="error-message">This field is required</p>}

              <label className="form-label">Why did you choose Advanced Cardiovascular Associates?</label>
              <div className="form-input radio-buttons">
                <label className="radio-option"><input {...register("choiceReason", { required: true })} type="radio" value="hospital" /> Hospital</label>
                <label className="radio-option"><input {...register("choiceReason", { required: true })} type="radio" value="physician" /> Physician</label>
                <label className="radio-option"><input {...register("choiceReason", { required: true })} type="radio" value="friend" /> A friend referred me</label>
                <label className="radio-option"><input {...register("choiceReason", { required: true })} type="radio" value="location" /> Convenient Location/Office Hours</label>
                <label className="radio-option"><input {...register("choiceReason", { required: true })} type="radio" value="website" /> Website</label>
                <label className="radio-option"><input {...register("choiceReason", { required: true })} type="radio" value="other" /> Other</label>
              </div>
              {errors.choiceReason && <p className="error-message">This field is required</p>}

              <button className="form-submit" type="submit">Submit</button>
            </form>
          </>
        )}
      </div>
    </div>
  );
}
