import React from 'react';
import './Doctors.css';
import doctorImage from '../Assets/luaysayed.jpg';
import doctorImage2 from '../Assets/docb.jpg';
import doctorImage3 from '../Assets/mattforcina.jpg';
import doctorImage4 from '../Assets/fadihanna.jpg';
import doctorImage5 from '../Assets/ramymando.jpg';

const Doctor = ({name, image, bio}) => (
  <div className="doctor-card">
    <img src={image} alt={name} className="doctor-image" />
    <div className="doctor-details">
      <h2 className="doctor-name">{name}</h2>
      <p className="doctor-bio">{bio}</p>
      {/* <button className="bio-button">Full Bio</button>  Added button */}
    </div>
  </div>
);

const Doctors = () => {
  const doctors = [
    {name: 'Dr. Luay Sayed M.D., FACC, FSCAI', image: doctorImage, bio: 'Cardiovascular Disease/Interventional Cardiology'},
    {name: 'Dr. David H Benaderet M.D., FACC', image: doctorImage2, bio: 'Cardiovascular Disease'},
    {name: 'Dr. Matthew S Forcina M.D., FACC', image: doctorImage3, bio: 'Cardiovascular Disease/Cardiac Electrophysiology'},
    {name: 'Dr. Fadi Raheem Al-Qas Hanna M.D., FACC', image: doctorImage4, bio: 'Cardiovascular Disease/Interventional Cardiology'},
    {name: 'Dr. Ramy Mando M.D., FACC', image: doctorImage5, bio: 'Cardiovascular Disease'}
  ];
  
  return (
    <div className="doctors-container">
      {doctors.map((doctor, index) => <Doctor key={index} {...doctor} />)}
    </div>
  );
};

export default Doctors;
