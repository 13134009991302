import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Hero.css';
import heroImage from '../Assets/hero.png';
import credImage from '../Assets/cred.png';
import credImage2 from '../Assets/cred2.png';
import credImage3 from '../Assets/cred3.png';
import credImage4 from '../Assets/cred4.png';
// import HeartModelComponent from './HeartModelComponent';
import backgroundImage from '../Assets/22222222222.jpg';

function Hero() {
    const [fadeIn, setFadeIn] = useState(false);
    const [bgAnimate, setBgAnimate] = useState(false);

    useEffect(() => {
        setFadeIn(true);
        setTimeout(() => {
            setBgAnimate(true);
        }, 2000);  // 2000 milliseconds delay for background animation
    }, []);

    return (
        <div className={`about ${bgAnimate ? 'bg-animate' : ''}`} style={{ backgroundImage: `url(${backgroundImage})` }}>
            <div className="about-content-image">
                <div className="cred-images">
                    <img src={credImage} alt="Credential"/>
                    <img src={credImage2} alt="Credential"/>
                    <img src={credImage3} alt="Credential"/>
                    <img src={credImage4} alt="Credential"/>
                </div>
                <div className="about-image">
                    <img src={heroImage} alt="Hero"/>
                </div>
                <div className="about-content">
                    <p>Let's get your heart in shape!</p>
                    <div className="about-buttons">
                        <Link to="/locations" className="contact-button">Locations</Link>
                        <Link to="/new-patients" className="services-button">New Patients</Link>
                    </div>
                </div>
            </div>
            <div className={`heart-container ${fadeIn ? 'fade-in' : ''}`}>
                {/* <HeartModelComponent /> */}
            </div>
        </div>
    );
}

export default Hero;
