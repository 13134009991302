import React from 'react';
import { Flex, Link, Icon, Box } from '@chakra-ui/react';
import { MdLocationOn } from 'react-icons/md';

// This is your new DividerLine component
const DividerLine = () => {
  return (
    <Box
      width="1px"
      backgroundColor="black"
      height="60%"
    />
  );
};

const Information = () => {
  return (
    <Flex
      justify="space-between"
      align="center"
      h="40px"
      backgroundColor="white"
    >
      {[
        { url: 'https://goo.gl/maps/cvASVW7njMN87jbQ6', full: '49050 Schoenherr Rd Suite #100 Shelby Twp, MI 48315', short: 'Schoenherr' },
        { url: 'https://goo.gl/maps/TuB8a9FCPmit8hfb8', full: '44850 Mound Rd, Sterling Heights, MI 48314', short: 'Mound' },
        { url: 'https://maps.app.goo.gl/1F43VzMe9zjuxwhy5', full: '14061 13 Mile Rd Suite #3, Warren, MI 48088', short: '13 Mile' },
      ].map((location, index, array) => (
        <React.Fragment key={location.full}>
          <Flex
            justify="center"
            align="center"
            flex="1"
            pl="10px"
            fontWeight="bold"
          >
            <Icon as={MdLocationOn} marginRight="10px" />
            <Link href={location.url} isExternal fontSize={['sm', 'md']} display={{ base: 'none', md: 'block' }}>
              {location.full}
            </Link>
            <Link href={location.url} isExternal fontSize={['sm', 'md']} display={{ base: 'block', md: 'none' }}>
              {location.short}
            </Link>
          </Flex>
          {/* Insert the DividerLine component conditionally */}
          {index < array.length - 1 && <DividerLine />}
        </React.Fragment>
      ))}
    </Flex>
  );
};

export default Information;
