import React from 'react';
import './HeaderTeam.css';

class Header extends React.Component {
  render() {
    return (
      <div className="medicalheader">
        <h1>Medical Team</h1>
      </div>
    );
  }
}

export default Header;
