import React from 'react';
import Home from './Pages/Home/Home';
import Services from './Pages/Services/Services';
import MedicalTeam from './Pages/MedicalTeam/MedicalTeam';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import './App.css';
import Navbar from './Pages/Components/Navbar';
import Footer from './Pages/Components/Footer';
import Information from './Pages/Components/Information';
import NewPatients from './Pages/NewPatients/NewPatients';
import Contact from './Pages/Contact/Contact';
import { ChakraProvider } from '@chakra-ui/react'; 
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


import heart from './Pages/Assets/Cursor/heart00.png';
import Chatbot from './Pages/Components/Chatbot';
import PrivacyPolicy from './Pages/Components/PrivacyPolicy';
import Terms from './Pages/Components/Terms';

function App() {
  const cursorIcon = `url(${heart}), auto`;

  return (
    <ChakraProvider>
      <div className="App" style={{ cursor: cursorIcon }}>
        <Router>
          <Information />
          <Navbar />
          <Chatbot />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/services" element={<Services />} />
            <Route path="/medical-team" element={<MedicalTeam />} />
            <Route path="/new-patients" element={<NewPatients />} />
            <Route path="/locations" element={<Contact />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-service" element={<Terms />} />
          </Routes>
          <Footer />
        </Router>
      </div>
    </ChakraProvider>
  );
}

export default App;
