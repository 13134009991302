import React, { useEffect, useState, useRef } from 'react';
import { Box, Image, Text, VStack, Flex } from '@chakra-ui/react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

import HeroImage2 from '../Assets/whyheroheart.jpg';
import HeroImage3 from '../Assets/acas.jpg';
import HeroImage4 from '../Assets/whyherodoc.jpg';
import HeroImage5 from '../Assets/acadoc2.jpg';
import BarGif from '../Assets/bar_1.gif';

const Why = () => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const currentRef = ref.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.5,
      }
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  return (
    <Box backgroundColor="#ececec" h="100%" w="100%" ref={ref} overflowX="hidden">
      <Flex 
        width={['100%', '100%', '80%']} 
        margin="auto" 
        alignItems="center" 
        direction={['column-reverse', 'column-reverse', 'column-reverse', 'row']}
        minH={['auto', 'auto', '80vh']}
        p={['2rem', '2rem', '0']}
      >
        {/* Left Side with Images */}
        <Box 
          width={['100%', '100%', '100%', '50%']} 
          mt={['1rem', '1rem', '0']}
        >
          <Carousel autoPlay infiniteLoop showArrows={false} showStatus={false} showIndicators={false} showThumbs={false}>
            <div>
              <Image src={HeroImage2} alt="Hero 2" style={{borderRadius: '25px'}}/>
            </div>
            <div>
              <Image src={HeroImage3} alt="Hero 3" style={{borderRadius: '25px'}}/>
            </div>
            <div>
              <Image src={HeroImage4} alt="Hero 4" style={{borderRadius: '25px'}}/>
            </div>
            <div>
              <Image src={HeroImage5} alt="Hero 5" style={{borderRadius: '25px'}}/>
            </div>
          </Carousel>
        </Box>

        {/* Right Side with Information */}
        <VStack 
          width={['100%', '100%', '100%', '50%']} 
          spacing={5} 
          className="column-right" 
          ml={[0, 0, 0, '50px']}
          opacity={isVisible ? 1 : 0}
          transform={`translateX(${isVisible ? '0' : '100px'})`}
          transition="all 0.5s ease-out"
        >
          <Text fontWeight="bold" fontSize={["30px", "40px", "50px"]} className="why-header"  mb={-6} >
            Why Choose ACA?
          </Text>
          <Image src={BarGif} alt="Bar Gif" className="barGif" />
          <Box className="why-item" maxWidth="800px">
            <Text as="h2" size="28px" textAlign="left">
              EXPERT CARDIOLOGY STAFF
            </Text>
            <Text fontSize="20px" textAlign="left">
              Our caring, knowledgeable, and experienced team provides exceptional cardiology care.
            </Text>
          </Box>
          <Box className="why-item" maxWidth="800px">
            <Text as="h2" size="28px" textAlign="left">
            CUTTING-EDGE TECHNOLOGY
            </Text>
            <Text fontSize="20px" textAlign="left">
            We utilize the latest and most effective heart health treatments, employing both medical and interventional approaches.
            </Text>
          </Box>
          <Box className="why-item" maxWidth="800px">
            <Text as="h2" size="28px" textAlign="left">
              HEART-HEALTHY RESULTS
            </Text>
            <Text fontSize="20px" textAlign="left">
              We help our patients achieve and maintain optimal cardiovascular health for a lifetime!
            </Text>
          </Box>
        
        </VStack>
      </Flex>
    </Box>
  );
};

export default Why;
