import React, { useState } from 'react';
import './TheServices.css';
import service1 from '../Assets/service1.jpg';
import service2 from '../Assets/service2.jpg';
import service3 from '../Assets/service3.jpg';
import service4 from '../Assets/service4.jpg';

const TheServices = () => {
  const [showMore, setShowMore] = useState({
    invasive: false,
    imaging: false,
    heart: false,
    stress: false,
  });

  const handleShowMore = (id) => {
    setShowMore((prevShowMore) => ({
      ...prevShowMore,
      [id]: !prevShowMore[id]
    }));
  };

  const services = [
    {
      id: 'invasive',
      title: 'Invasive Procedures',
      image: service1,
      description: 'Invasive Procedures generally refers to a spectrum of medical operations performed to diagnose, treat, or manage various heart and blood vessel conditions. These procedures are "invasive" because they involve the insertion of instruments or devices into the body, typically through small incisions or natural body openings. These might include angioplasties, where a small balloon is used to open up a narrowed or blocked blood vessel, or cardiac catheterizations, where a long, thin tube is inserted into a blood vessel to reach the heart. These techniques allow physicians to directly visualize the heart and its vessels, perform tests, and treat conditions that would otherwise require more extensive surgery. Despite being termed invasive, these procedures are generally safe, minimally invasive, and done with the aid of local anesthesia or mild sedation.'
    },
    {
      id: 'imaging',
      title: 'Advanced Imaging',
      image: service2,
      description: 'Advanced Imaging is the use of high-precision, state-of-the-art imaging techniques to assess the hearts structure and function, and the state of the blood vessels. This could include a variety of diagnostic methods such as cardiac magnetic resonance imaging (MRI), computed tomography (CT) scans, positron emission tomography (PET) scans, and 3D echocardiography. These technologies produce detailed images that help physicians diagnose heart conditions, assess the extent of damage from heart disease, plan appropriate treatments, and monitor patient progress. Advanced imaging can noninvasively reveal a wide range of cardiovascular abnormalities and diseases, making it an indispensable tool for comprehensive cardiac care.'
    },
    {
      id: 'heart',
      title: 'Heart Monitoring',
      image: service3,
      description: 'Heart Monitoring  entails the continuous or periodic tracking of a patients heart activity to detect irregularities, assess heart health, or monitor the progress of treatment. This can involve various techniques such as electrocardiograms (ECGs or EKGs), Holter monitoring, event recorders, or implantable devices like loop recorders. These tools can capture vital information such as heart rate, rhythm, electrical activity, and in some cases, blood pressure. This data aids in diagnosing arrhythmias, coronary artery disease, heart failure, and other cardiovascular conditions. Additionally, heart monitoring is crucial for patients who have received treatments like pacemaker implantation or ablation therapy, to ensure the effectiveness of these interventions over time. Heart Monitoring is a non-invasive, often patient-friendly way to keep a close check on heart health.'
    },
    {
      id: 'stress',
      title: 'Stress Testing',
      image: service4,
      description: 'Stress Testing is a diagnostic procedure used to measure the hearts performance and response to external stress, typically induced by exercise or medication. During the test, a patient might walk or run on a treadmill or pedal a stationary bike while their heart rate, blood pressure, and electrocardiogram (EKG) readings are continuously monitored. For those unable to exercise, medications can be used to stimulate the heart in a way that mimics the effects of physical activity. The goal is to identify if there are abnormalities in blood flow within the heart, assess the level of physical activity an individual can safely perform, or predict the risk of heart disease. Stress testing is essential for diagnosing conditions such as coronary artery disease or heart arrhythmias, and it plays a significant role in creating personalized treatment plans.'
    },
  ];

  return (
    <div className="servicesContainer">
      {services.map(({ id, title, image, description }) => (
        <div className="serviceItem" id={id} key={id}>
          <img src={image} alt={title} className="serviceImage"/>
          <div className={`serviceText ${showMore[id] ? 'expanded' : 'collapsed'}`}>
            <h2>{title}</h2>
            <p>
              {showMore[id] ? description : `${description.substring(0, 200)}...`}
              <button onClick={() => handleShowMore(id)}>
                {showMore[id] ? 'Show Less' : 'Show More'}
              </button>
            </p>
          </div>
        </div>
      ))}
    </div>
  );  
};

export default TheServices;
