import React from 'react';
import Header from './Header';
import Locations from './Locations';
import Greeting from './Greeting';
import Testimonials from '../Components/Testimonials';

class Contact extends React.Component {
    render() {
        return (
            <>
                <Header />
                <Greeting />
                <Locations />
                <Testimonials/>
            </>
        );
        
    }
}

export default Contact;
