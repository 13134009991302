import React from 'react';
import Meet from './Meet';
import HeaderTeam from './HeaderTeam';
import Doctors from './Doctors';

class Home extends React.Component {
    render() {
        return (
            <>
                <HeaderTeam/>
                <Doctors />
                <Meet />
            </>
        );
        
    }
}

export default Home;
