import { Container, Heading, Text } from '@chakra-ui/react';

const PrivacyPolicy = () => {
  return (
    <Container maxW="container.xl" py={12} textAlign="left">
      <Heading as="h1" size="2xl" mb={6}>
        Privacy Policy
      </Heading>
      <Text mb={4}>
        Last Revision Date: October 11, 2023
      </Text>
      <Text mb={4}>
        Welcome to Advanced Cardiovascular, your trusted partner in cardiovascular care. We take the responsibility of handling your information with the utmost seriousness. This Privacy Policy serves as a comprehensive guide, outlining how we collect, safeguard, and utilize your personal and medical data.
      </Text>
      <Text mb={4}>
        When you engage with our platform, we gather a variety of information for different purposes. Personal Information includes but is not limited to your full name, date of birth, email address, mailing address, and phone numbers. Medical Information can encompass sensitive data such as heart rate, electrocardiograms, blood pressure, and your detailed medical history.
      </Text>
      <Text mb={4}>
        The Personal and Medical Information we collect is foundational for the healthcare services we provide. This data facilitates account creation, transaction processes, and enables us to offer customized healthcare solutions. Beyond the provision of services, compliance with regulatory mandates such as the Health Insurance Portability and Accountability Act (HIPAA) is another major area where your information is vital.
      </Text>
      <Text mb={4}>
        The data we collect isn't stored in isolation; it is sometimes shared with key stakeholders for specific purposes. Your Personal and Medical Information may be disclosed to healthcare providers for diagnosis, treatment planning, and consultations. There are also legal scenarios that may necessitate the sharing of your data, such as in response to lawful requests from authorities or to prevent fraudulent activities.
      </Text>
      <Text mb={4}>
        Cookies are tiny files stored on your device to personalize and enhance your interaction with our platform. These help us understand user behavior, optimize site functionality, and provide a more seamless browsing experience.
      </Text>
      <Text mb={4}>
        Our commitment to data integrity extends to ensuring that you have substantial control over your Personal and Medical Information. You are entitled to access your stored data at any point, correct any inaccuracies, and in some regulated scenarios, request the removal of your data from our databases.
      </Text>
      <Text mb={4}>
        The security of your data is our top priority. As such, we have integrated advanced encryption methods and robust firewalls to deter unauthorized access. Our team continually updates our security protocols to adapt to emerging threats and maintain the integrity of your data.
      </Text>
      <Text mb={4}>
        We hold onto your information for as long as your account is active. In some cases, we are legally obligated to retain data for a specific period, even after account deactivation, to meet compliance standards and for auditing purposes.
      </Text>
      <Text mb={4}>
        Currently, all data collected is stored and processed within the jurisdiction of its collection. We do not engage in the international transfer of data.
      </Text>
      <Text mb={4}>
        Although we strive to provide a secure environment, we cannot vouch for the privacy practices of third-party websites you may access through links on our platform.
      </Text>
      <Text mb={4}>
        We periodically revise our Privacy Policy. When we do, the revised policy will be uploaded on this page, and the “Last Revision Date” will be updated. We encourage you to review this policy regularly.
      </Text>
      <Text mb={4}>
        If you have any inquiries or concerns regarding this Privacy Policy, please do not hesitate to call us at 586-566-7870.
      </Text>
    </Container>
  );
};

export default PrivacyPolicy;
