import React, { useEffect, useRef, useState } from 'react';
import { Box, Text, useMediaQuery } from '@chakra-ui/react';

const Mission = () => {
  const [isSmallScreen] = useMediaQuery('(max-width: 800px)');
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const currentRef = ref.current;  // Copy ref.current to a local variable

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      {
        threshold: 0.3,
      }
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);  // Use the local variable here
      }
    };
  }, []);  // Empty dependency array means this useEffect runs once

  return (
    <Box
      width={isSmallScreen ? '80%' : '50%'}
      margin={isSmallScreen ? '40px auto 40px' : '140px auto'}
      padding={isSmallScreen ? '10px' : '20px'}
      ref={ref}
      opacity={isVisible ? 1 : 0}
      transform={`translateY(${isVisible ? '0' : '150px'})`}
      transition="all 1s ease-out"
    >
      <Text
        as="h2"
        fontSize={["30px", "40px", "60px"]}
        fontWeight="bold"
        margin="0"
      >
        Mission Statement
      </Text>
      <Text
        fontSize="24px"
        lineHeight="1.5"
        mt={4}  // Added margin-top here
      >
        At Advanced Cardiovascular Associates, we lead in heart health with compassionate, innovative care. We focus on improving lives through research, personalized treatments, and a holistic consideration of well-being. Our goal is to empower patients with knowledge and foster a supportive community with staff and family collaboration. Our commitment to excellence and patient-centered care makes us a beacon for those with heart-related ailments, working together for a future where every heart beats strong.
      </Text>
    </Box>
  );
};

export default Mission;
