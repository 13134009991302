import React from 'react';
import Header from './Header';
import Greeting from './Greeting';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';  // Import StepThree
import './NewPatients.css';
import Testimonials from '../Components/Testimonials';
import Portal from './Portal';

class NewPatients extends React.Component {
    render() {
        return (
            <>
                <Header/>
                <Greeting />
                <div className="parent-container">
                    <div className="steps-container">
                        <StepOne />
                        <div className="right-steps"> {/* New div to group StepTwo and StepThree */}
                            <StepTwo />
                            <StepThree />
                        </div>
                    </div>
                </div>
                <Portal />
                <Testimonials/>
            </>
        );
    }
}

export default NewPatients;
