import React from 'react';
import Hero from './Hero';
import OurServices from './OurServices';
import Why from './Why';
import About from './About';
import Testimonials from '../Components/Testimonials';
import Mission from './Mission';
import Logos2 from './Logos';

class Home extends React.Component {
    componentDidMount() {
        const hash = window.location.hash;
        if (hash) {
            const element = document.getElementById(hash.replace('#', ''));
            if (element) element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    render() {
        return (
            <>
                <Hero />
                <Why />
                <OurServices />
                <div id="about-us">
                  <About />
                </div>
                <Mission />
                <Logos2 />
                <Testimonials/>
            </>
        );
    }
}

export default Home;
