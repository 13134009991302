import { Container, Heading, Text } from '@chakra-ui/react';

const Terms = () => {
  return (
    <Container maxW="container.xl" py={12} textAlign="left">
      <Heading as="h1" size="2xl" mb={6}>
        Terms of Service
      </Heading>
      <Text mb={4}>
        Last Updated: October 11, 2023
      </Text>
      <Text mb={4}>
        Welcome to Advanced Cardiovascular Group, a collaborative of cardiologists dedicated to exceptional cardiovascular healthcare. This document outlines the Terms of Service ("Terms") governing your access and use of our medical services, patient portal, mobile applications, and other digital platforms ("Services"). By accessing or using our Services, you agree to be bound by these Terms and our Privacy Policy.
      </Text>
      <Text mb={4}>
        We reserve the right to update, modify, or otherwise alter these Terms at any time. Changes will become effective immediately upon posting. Your continued use of the Services after such changes will constitute your acceptance of the revised Terms.
      </Text>
      <Text mb={4}>
        Our Services include a range of cardiological examinations, diagnosis, medication planning, surgical consultations, second opinions, telemedicine, and research-based treatments. All these services are offered and executed by licensed medical professionals.
      </Text>
      <Text mb={4}>
        Please note that our Services are not a replacement for in-person medical consultation and emergency medical services. If you are experiencing a medical emergency, dial emergency services immediately.
      </Text>
      <Text mb={4}>
        While we use state-of-the-art technologies and protocols to offer you the best possible healthcare services, we do not guarantee the completeness, accuracy, or efficacy of any tests, treatment plans, or healthcare information available through our Services. Any reliance upon or use of our Services shall be at your sole discretion and risk.
      </Text>
      <Text mb={4}>
        You are obligated to provide complete, updated, and accurate information, including your personal details, medical history, and insurance data, for a fruitful healthcare experience. Misrepresentation or omission of any details can affect the quality of healthcare services you receive and may also result in legal consequences.
      </Text>
      <Text mb={4}>
        Our Services may integrate third-party diagnostic labs, pharmacies, and other healthcare providers to offer a comprehensive healthcare package. We make rigorous checks to ensure the credibility of these third parties; however, we cannot be held responsible for their conduct, services, or any breach of contract.
      </Text>
      <Text mb={4}>
        We may provide links to third-party websites or applications for your convenience. Such links do not signify endorsement, and we are not responsible for the content, privacy policies, or practices of such third-party platforms.
      </Text>
      <Text mb={4}>
        The intellectual property associated with our Services, including copyrights, trademarks, service marks, and patents, are exclusively owned by Advanced Cardiovascular Group or our licensors. Unauthorized use, reproduction, or distribution is strictly prohibited and may result in legal action.
      </Text>
      <Text mb={4}>
        We reserve the right to modify, suspend, or terminate your account and access to our Services for violations of these Terms, illegal activities, or for any other reason at our sole discretion, with or without notice.
      </Text>
      <Text mb={4}>
        These Terms are governed by and will be construed under the laws of the jurisdiction in which Advanced Cardiovascular Group is registered, notwithstanding the conflict of laws principles.
      </Text>
      <Text mb={4}>
        Disputes arising out of these Terms shall be subject to the jurisdiction of courts situated in the state of our registration. Both parties agree to attempt to resolve any disputes amicably before resorting to legal action.
      </Text>
      <Text mb={4}>
        If you have any queries, concerns, or require clarification regarding these Terms of Service, you may contact us at our customer service number: 586-566-7870.
      </Text>
    </Container>
  );
};

export default Terms;
