import React from 'react';
import './Portal.css';

const Portal = () => {
  return (
    <div className="my-container">
      <h2 className='my-text'>Exisiting Patients</h2>
      <p className="my-paragraph">Are you an exisitng patient? Visit the portal to see all your health information.</p>
      <a href="https://mybeaumontchart.com/mychart/Authentication/Login" className="my-button">myBeaumont Chart</a>
    </div>
  );
}

export default Portal;
