import React from 'react';
import './HeaderService.css';

class Header extends React.Component {
  render() {
    return (
      <div className="serviceheader">
        <h1>Services</h1>
      </div>
    );
  }
}

export default Header;
