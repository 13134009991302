import React, { useState } from 'react';
import {
  Box,
  Flex,
  Link,
  Spacer,
  IconButton,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  VStack,
  useBreakpointValue 
} from '@chakra-ui/react';
import { FiMenu } from 'react-icons/fi';
import { Link as RouterLink } from 'react-router-dom';
import logo from '../Assets/finalogopng.png';

const linkStyles = {
  pr: '15px',
  textDecoration: 'none',
  color: 'white',
  fontSize: '26px',
  fontWeight: 'bold',
  _hover: { color: 'black', transition: 'color 0.3s ease-in-out' }
};

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const logoWidth = useBreakpointValue({ base: '280px', xl: '350px' });

  const onClose = () => setIsOpen(false);
  const toggleDrawer = () => setIsOpen(!isOpen);

  const drawerCloseButtonStyles = {
    fontSize: '24px',
    color: 'white',
    boxSize: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '20px',
    marginRight: '20px'
  };

  const drawerLinkStyles = {
    ...linkStyles,
    fontSize: '32px',
    marginTop: '8px'
  };

  return (
    <Box bg="#f09797" p={3} position="sticky"  top="0" zIndex="10">
      <Flex w={{ base: "97%", xl: "90%" }} mx="auto" alignItems="center">
        <Box>
          <Link as={RouterLink} to="/">
            <img
              src={logo}
              alt="Logo"
              style={{
                height: 'auto',
                width: logoWidth,
              }}
            />
          </Link>
        </Box>
        <Spacer />
        <Box display={{ base: 'none', xl: 'block' }}>
          <Link as={RouterLink} to="/" {...linkStyles}>Home</Link>
          <Link as={RouterLink} to="/medical-team" {...linkStyles}>Medical Team</Link>
          <Link as={RouterLink} to="/services" {...linkStyles}>Services</Link>
          <Link as={RouterLink} to="/new-patients" {...linkStyles}>Patients</Link>
          <Link href="https://ezpay.com" isExternal {...linkStyles}>Payments</Link>
          <Link as={RouterLink} to="/locations" {...linkStyles}>Locations</Link>
        </Box>
        <Box display={{ base: 'block', xl: 'none' }} justifyContent="center" alignItems="center" pb={2}>
        <IconButton
            icon={<FiMenu size={45} />}
            color="white"
            variant="unstyled"
            onClick={toggleDrawer}
          />
        </Box>
      </Flex>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay>
          <DrawerContent bg="#f09797">
            <DrawerCloseButton {...drawerCloseButtonStyles} />
            <DrawerBody>
              <VStack spacing={4} align="start" mt={10}>
                <Link as={RouterLink} to="/" onClick={onClose} {...drawerLinkStyles}>Home</Link>
                <Link as={RouterLink} to="/medical-team" onClick={onClose} {...drawerLinkStyles}>Medical Team</Link>
                <Link as={RouterLink} to="/services" onClick={onClose} {...drawerLinkStyles}>Services</Link>
                <Link as={RouterLink} to="/new-patients" onClick={onClose} {...drawerLinkStyles}>Patients</Link>
                <Link href="https://ezpay.com" isExternal onClick={onClose} {...drawerLinkStyles}>Payments</Link>
                <Link as={RouterLink} to="/locations" onClick={onClose} {...drawerLinkStyles}>Locations</Link>
              </VStack>
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </Box>
  );
};

export default Navbar;
