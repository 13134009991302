import React, { useState, useEffect, useRef } from 'react';
import { Box, Text, Flex, IconButton } from '@chakra-ui/react';
import Slider from 'react-slick';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const SlickArrow = ({ onClick, direction }) => {
  return (
    <IconButton
      icon={direction === 'left' ? <FiChevronLeft /> : <FiChevronRight />}
      onClick={onClick}
      fontSize="3xl"
      position="absolute"
      top="50%"
      zIndex="1"
      left={direction === 'left' ? '-20px' : 'auto'}  // Moved 10px to the left
      right={direction === 'right' ? '-20px' : 'auto'}  // Moved 10px to the right
      backgroundColor="white"
    />
  );
};

const Testimonials = () => {
  const testimonials = [
    { 
      text: "Great Staff! They help me with my blood pressure all the time. They are super friendly and responsive! Place is clean and well established. Highly highly recommended more than any other cardiologist out there! They do great work!",
      rating: 5, 
      link: 'https://link1.com', 
      name: 'Mike Powers' 
    },
    { 
      text: "Staff is efficient and very pleasant. I was able to get in to see the physician right away. Office was easy to find and very clean. I would recommend this office to friends and family.",
      rating: 5, 
      link: 'https://link2.com', 
      name: 'Helen Bridges' 
    },
    { 
      text: "Dr. Sayed and Associates have kept me alive and spending time with Family and doing things that I love for over a decade and I have some serious heart issues. I recommend the Team to all my Family and Friends. The Staff and Office Personnel has always treated me with Respect and went Above and Beyond.",
      rating: 5, 
      link: 'https://link3.com', 
      name: 'John Finger' 
    },
  ];

 
  const [current, setCurrent] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const sliderRef = useRef(null);
  const componentRef = useRef(null);

  useEffect(() => {
    const currentRef = componentRef.current; // Make a local copy of componentRef.current
  
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      {
        threshold: 0.1,
      }
    );
  
    if (currentRef) {
      observer.observe(currentRef); // Use the local copy here
    }
  
    return () => {
      if (currentRef) {
        observer.unobserve(currentRef); // Use the local copy here
      }
    };
  }, []);
  

  useEffect(() => {
    const timer = setTimeout(() => {
      setCurrent((prevCurrent) => (prevCurrent + 1) % testimonials.length);
      sliderRef.current.slickNext();
    }, 10000);

    return () => clearTimeout(timer);
  }, [current, testimonials.length]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current, next) => setCurrent(next),
    nextArrow: <SlickArrow direction="right" />,
    prevArrow: <SlickArrow direction="left" />
  };

  return (
    <Flex 
      justify="center" 
      align="center" 
      p={{ base: 2, md: 4 }} 
      h={{ base: "auto", md: "50vh" }}
      ref={componentRef}
      opacity={isVisible ? 1 : 0}
      transition="opacity 2s ease-out"
      my="20px"
    >
      <Box w={{ base: "90%", md: "60%" }} p={4} position="relative">
        <Slider {...settings} ref={sliderRef}>
          {testimonials.map((testimonial, index) => (
            <Box key={index} textAlign="center" p={4}>
              <Text fontSize={{ base: "20px", md: "24px", lg: "28px" }} mb={4}>
                <a href={testimonial.link} target="_blank" rel="noopener noreferrer">
                  {testimonial.text}
                </a>
              </Text>
              <Text fontWeight="bold" fontSize={{ base: "20px", md: "24px", lg: "28px" }} mb={2}>
                {testimonial.name}
              </Text>
              <Text fontSize={{ base: "20px", md: "24px", lg: "40px" }} color="tomato">
                {'\u2605'.repeat(testimonial.rating)}
              </Text>
            </Box>
          ))}
        </Slider>
      </Box>
    </Flex>
  );
};

export default Testimonials;