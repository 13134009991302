// OurServices.js
import React, { useEffect, useRef, useState } from 'react';
import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import InvasiveProceduresImg from '../Assets/service1.jpg';
import AdvancedImagingImg from '../Assets/service2.jpg';
import HeartMonitoringImg from '../Assets/service3.jpg';
import StressTestingImg from '../Assets/service4.jpg';
import './OurServices.css';

const OurServices = () => {
  const services = [
    { name: 'Invasive Procedures', image: InvasiveProceduresImg },
    { name: 'Advanced Imaging', image: AdvancedImagingImg },
    { name: 'Heart Monitoring', image: HeartMonitoringImg },
    { name: 'Stress Testing', image: StressTestingImg },
  ];

  const [isVisible, setIsVisible] = useState(false);
  const containerRef = useRef();

  useEffect(() => {
    const currentRef = containerRef.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: .2 }
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  return (
    <Flex 
      ref={containerRef}
      maxW="80%" 
      mx="auto" 
      mb={20} 
      minH="80vh" 
      direction="column" 
      justify="center" 
      align="center"
    >
      <Heading as="h2" fontSize={["30px", "40px", "60px"]} my={10} style={{ fontFamily: "'Sofia', sans-serif" }}>
        What We Offer
      </Heading>
      <Flex 
        width="100%" 
        direction={{ base: 'column', md: 'row' }} 
        justify="space-around" 
        align="stretch" 
        wrap="wrap"
      >
        {services.map((service, index) => (
          <Box
            key={index}
            className={isVisible ? 'fade-in' : ''}
            style={{ animationDelay: `${index * 0.3}s` }}
            w={{ base: '100%', md: '46%', lg: '20%' }}
            p={{ base: 4, md: 6 }}
            m={{ base: '6% auto', md: '2%' }}
            bg="#f0f0f0"
            borderRadius="md"
            textAlign="center"
            transition="transform 0.3s ease-in-out, boxShadow 0.3s ease-in-out"
            _hover={{ 
              transform: 'scale(1.07)',
              boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.2)'
            }}
          >
            <Box as="img" src={service.image} alt={service.name} borderRadius="md" mb={4} />
            <Text fontSize="28px" fontWeight="bold" mb={4}>
              {service.name}
            </Text>
            <Link to="/services">
              <Text fontSize="24px" color="#f09797">
                Learn More
              </Text>
            </Link>
          </Box>
        ))}
      </Flex>
    </Flex>
  );
};

export default OurServices;
