import React, { useState, useEffect } from 'react';
import './Locations.css';
import { MdLocationOn, MdLocalPhone, MdAccessTime } from 'react-icons/md';

function Locations() {
  const addresses = [
    { 
      name: '22 Mile & Schoenherr', 
      address: '49050 Schoenherr Rd #100, Shelby Twp, MI 48315', 
      addressLink: 'https://goo.gl/maps/cvASVW7njMN87jbQ6',
      phoneNumber: 'Tel: +1 (586) 731-7000',
      iframeSrc: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d23493.31343272793!2d-83.01411104650425!3d42.60486806248895!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8824e7533379692f%3A0xa2a732881b7fdd45!2sAdvanced%20Cardiovascular%20Associates%2C%20PLC!5e0!3m2!1sen!2sus!4v1690852465123!5m2!1sen!2sus',
      hours: [
        'Mon: 9 AM–5 PM',
        'Tue: 9 AM–5 PM',
        'Wed: 9 AM–5 PM',
        'Thu: 9 AM–4 PM',
        'Fri: 9 AM–1 PM Phone Only',
        'Sat: Closed',
        'Sun: Closed',
      ],
    },
    { 
      name: 'Mound', 
      address: '44850 Mound Rd, Sterling Heights, MI 48314',
      addressLink: 'https://goo.gl/maps/TuB8a9FCPmit8hfb8',
      phoneNumber: 'Tel: +1 (586) 731-7000',
      iframeSrc: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11743.095460676272!2d-83.0513607!3d42.6237517!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8824db6db0798b71%3A0x10941e5d3c958da2!2sAdvanced%20Cardiovascular!5e0!3m2!1sen!2sus!4v1690852280098!5m2!1sen!2sus',
      hours: [
        'Mon: 8:30 AM–6 PM',
        'Tue: 8:30 AM–5 PM',
        'Wed: 8:30 AM–5 PM',
        'Thu: 8:30 AM–5 PM',
        'Fri: 10:30 AM–2:30 PM',
        'Sat: Closed',
        'Sun: Closed',
      ],
    },
    { 
      name: '13 Mile', 
      address: '14061 13 Mile Rd Suite #3, Warren, MI 48088',
      addressLink: 'https://maps.app.goo.gl/1F43VzMe9zjuxwhy5',
      phoneNumber: 'Tel: +1 (586) 731-7000',
      iframeSrc: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d94022.11439838153!2d-83.0969582!3d42.5724667!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8824d9c5d89a7dcf%3A0xb0441396a16fe3ec!2sAdvanced%20Cardiovascular%20Associates!5e0!3m2!1sen!2sus!4v1696367993869!5m2!1sen!2sus',
      hours: [
        'Mon: Closed',
        'Tue: Closed',
        'Wed: Closed',
        'Thu: 9 AM–4 PM',
        'Fri: Closed',
        'Sat: Closed',
        'Sun: Closed',
      ],
    },
  ];

  const [activeLocation, setActiveLocation] = useState(addresses[0]);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        setLoaded(true);
    }, []);

    const handleClick = (location) => {
        setActiveLocation(location);
    }

    return (
        <div className={`container ${loaded ? 'fade-in' : ''}`}>
            <div className="locations">
                {addresses.map((location, i) => (
                    <div key={i} className={`address ${activeLocation.name === location.name ? 'active' : ''}`}>
                        <button className="button-link" onClick={() => handleClick(location)}>
                            <h2>{location.name}</h2>
                            <div className="icon-text-container">
                                <div className="icon"><MdLocationOn /></div>
                                <p><a href={location.addressLink} target="_blank" rel="noopener noreferrer" className="address-link">{location.address}</a></p>
                            </div>
                            <div className="icon-text-container">
                                <div className="icon"><MdLocalPhone /></div>
                                <p><a href={`tel:${location.phoneNumber.replace(/\D/g, '')}`} className="phone-link">{location.phoneNumber}</a></p>
                            </div>
                            <div className={`hours-container ${activeLocation.name === location.name ? 'active' : ''}`}>
                                <div className="icon-text-container">
                                    <div className="icon"><MdAccessTime /></div>
                                    <h3>Hours</h3>
                                </div>
                                {location.hours.map((hour, index) => <p key={index} style={{ marginLeft: '34px' }}>{hour}</p>)}
                            </div>
                        </button>
                    </div>
                ))}
            </div>
            <div className="mapContainer">
                <iframe
                    title="Location Map"
                    className="map"
                    src={activeLocation.iframeSrc}
                    loading="lazy"
                    allowFullScreen
                    referrerPolicy="no-referrer-when-downgrade"
                    style={{ height: '100%' }}>
                </iframe>
            </div>
        </div>
    );
}

export default Locations;