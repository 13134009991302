import React from 'react';
import './Meet.css';
import sara from '../Assets/pa.jpg';
import katlynn from '../Assets/pa2.jpg';
import steph from '../Assets/pa3.jpg';
import chris from '../Assets/pa4.jpg';
import deana from '../Assets/pa5.jpg';
import simona from '../Assets/pa6.jpg';

const teamMembers = [
  {
    name: 'Chris Davis',
    imageUrl: chris,
    description: 'Physician Assistant'
  },
  {
    name: 'Stephanie Bonynge',
    imageUrl: steph,
    description: 'Nurse Practitioner'
  },
  {
    name: 'Deana Hays',
    imageUrl: deana,
    description: 'Nurse Practitioner'
  },
  {
    name: 'Katlynn Burger',
    imageUrl: katlynn,
    description: 'Physician Assistant'
  },
  {
    name: 'Simona Crkovski',
    imageUrl: simona,
    description: 'Nurse Practitioner'
  },
  {
    name: 'Sara Ruch',
    imageUrl: sara,
    description: 'Physician Assistant'
  },
];

const Meet = () => (
  <div className="meet-the-team">
    <div className="grid">
      {teamMembers.map((member, index) => (
        <div key={index} className="grid-item">
          {/* Apply the team-member-image class to your image */}
          <img className="team-member-image" src={member.imageUrl} alt={member.name} />
          <h4>{member.name}</h4>
          <p>{member.description}</p>
        </div>
      ))}
    </div>
  </div>
);

export default Meet;
