import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { MdLocationOn, MdLocalPhone } from 'react-icons/md'; // Importing location and phone icons
import { FaFacebookF, FaLinkedinIn, FaInstagram } from 'react-icons/fa'; // Importing social media icons
import './Footer.css';

function Footer() {
    // Create a reference to the top of the page
    const topRef = useRef(null);

    // Function to smoothly scroll to the top of the page
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <div className="footer" ref={topRef}>
            <div className="footer__content">
                <div className="footer__column">
                    <h2>LOCATIONS</h2>
                    <div className="footer__location">
                        <div className="footer__location-item">
                            <span className="footer__icon"><MdLocationOn /></span>
                            <a href="https://goo.gl/maps/cvASVW7njMN87jbQ6" target="_blank" rel="noopener noreferrer" className="footer__link">
                                49050 Schoenherr Rd,<br />
                                Suite #100,<br />
                                Shelby Twp, MI 48315
                            </a>
                        </div>
                        <div className="footer__location-item">
                            <span className="footer__icon"><MdLocalPhone /></span>
                            <a href="tel:(586) 731-7000" className="footer__link">Tel: +1 (586) 731-7000</a>
                        </div>
                        <div className="footer__line"></div>
                        <div className="footer__location-item">
                            <span className="footer__icon"><MdLocationOn /></span>
                            <a href="https://goo.gl/maps/TuB8a9FCPmit8hfb8" target="_blank" rel="noopener noreferrer" className="footer__link">
                                44850 Mound Rd,<br />
                                Sterling Heights, MI 48314
                            </a>
                        </div>
                        <div className="footer__location-item">
                            <span className="footer__icon"><MdLocalPhone /></span>
                            <a href="tel:(586) 731-7000" className="footer__link">Tel: +1 (586) 731-7000</a>
                        </div>
                        <div className="footer__line"></div>
                        <div className="footer__location-item">
                            <span className="footer__icon"><MdLocationOn /></span>
                            <a href="https://maps.app.goo.gl/1F43VzMe9zjuxwhy5" target="_blank" rel="noopener noreferrer" className="footer__link">
                            14061 13 Mile Rd,<br />
                                Suite #3,<br />
                                Warren, MI 48088
                            </a>
                        </div>
                        <div className="footer__location-item">
                            <span className="footer__icon"><MdLocalPhone /></span>
                            <a href="tel:(586) 731-7000" className="footer__link">Tel: +1 (586) 731-7000</a>
                        </div>
                    </div>
                </div>
                <div className="footer__column">
                    <h2>SOCIALS</h2>
                    <div className="footer__social-icons">
                        <FaFacebookF className="footer__social-icon" />
                        <FaLinkedinIn className="footer__social-icon" />
                        <FaInstagram className="footer__social-icon" />
                    </div>
                </div>
                <div className="footer__column">
                    <h2>QUICK LINKS</h2>
                    <Link to="/" onClick={scrollToTop}>About</Link>
                    <Link to="/medical-team" onClick={scrollToTop}>Medical Team</Link>
                    <Link to="/services" onClick={scrollToTop}>Services</Link>
                    <Link to="/new-patients" onClick={scrollToTop}>New Patients</Link>
                    <Link to="/locations" onClick={scrollToTop}>Locations</Link>
                </div>
                <div className="footer__column">
                    <h2>PATIENT INFO</h2>
                    <Link to="/new-patients" onClick={scrollToTop}>Patient Forms</Link>
                    <a href="https://example.com/bill-pay" target="_blank" rel="noopener noreferrer">Online Bill Pay</a>
                    <Link to="/new-patients" onClick={scrollToTop}>Patient Portal</Link>
                </div>
            </div>
            <div className="footer__copyright">
                <p>© 2023 Advanced Cardiovascular Associates. All Rights Reserved.</p>
                <div className="footer__legal-links">
                    <Link to="/privacy-policy">Privacy Policy</Link>
                    <Link to="/terms-of-service">Terms of Service</Link>
                </div>
                <p className="footer__developed">
                    Developed by <a href="https://horizonlabsai.com" target="_blank" rel="noopener noreferrer">Horizon Labs</a>
                </p>
            </div>
        </div>
    );
}

export default Footer;
