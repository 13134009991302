import React from 'react';
import TheServices from './TheServices';
import Header from './HeaderService';
import FAQ from './FAQ';

class Home extends React.Component {
    render() {
        return (
            <>
                <Header/>
                <TheServices/>
                <FAQ />
            </>
        );
        
    }
}

export default Home;
