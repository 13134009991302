import './FAQ.css';
import React, { useState } from 'react';
import { MdKeyboardArrowRight, MdKeyboardArrowDown } from 'react-icons/md';
import { Link } from 'react-router-dom';


const questionsAndAnswers = [
  {
    question: 'What is a cardiologist?',
    answer: 'A cardiologist is a doctor with special training and skill in finding, treating, and preventing diseases of the heart and blood vessels.',
  },
  {
    question: 'How often should I see a cardiologist?',
    answer: 'This depends on your individual heart health. Some may need to see a cardiologist regularly, while others may only need occasional visits.',
  },
  {
    question: 'What should I expect during my first visit?',
    answer: 'During your first visit, the cardiologist will review your medical history, perform a physical examination, and may order additional tests.',
  },
  {
    question: 'Can I eat before a cardiology exam?',
    answer: 'Some exams require fasting, while others do not. It’s best to ask your healthcare provider for specific instructions before your appointment.',
  },
  {
    question: 'How can I make an appointment?',
    answer: 'You can make an appointment by calling our office of visiting us at one of our locations.',
  },
];

const ScrollToTopLink = ({ to, children, style }) => {
  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Link to={to} style={style} onClick={handleClick}>
      {children}
    </Link>
  );
};

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleQuestionClick = (index) => {
    if (index === activeIndex) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  return (
    <div className="faq-wrapper">
      <div className="faq-container">
        <h1>Frequently Asked Questions</h1>
        {questionsAndAnswers.map((item, index) => (
          <div key={index} className="faq-item">
            <h3 onClick={() => handleQuestionClick(index)} className="faq-question">
              {index === activeIndex ? <MdKeyboardArrowDown className="faq-icon" /> : <MdKeyboardArrowRight className="faq-icon" />}
              {item.question}
            </h3>
            <p className={index === activeIndex ? 'faq-answer active' : 'faq-answer'}>{item.answer}</p>
          </div>
        ))}
      </div>
      <div className="appointment-container">
        <h2>Schedule an Appointment Today!</h2>
        <p>You can make an appointment by calling one of our offices or by visiting us at one of our{' '}
          <ScrollToTopLink to="/locations" style={{ color: '#f09797' }}>locations</ScrollToTopLink>
        </p>
      </div>
    </div>
  );
};

export default FAQ;