import React, { useEffect, useRef, useState } from 'react';
import { Box, Text, Flex, Image } from '@chakra-ui/react';
import hero4 from '../Assets/acateam.jpg';

const About = () => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const currentRef = ref.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.3,
      }
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  return (
    <Flex
      width="80%"
      minH="60vh"
      maxH="auto"
      margin="auto"
      flexDirection={['column', 'column', 'column', 'row']}
      alignItems="center"
      justifyContent="space-between"
      gap={8}
      px={[1, 1, 0]}  // Reduced padding for smaller screens
      ref={ref}
    >
      <Box
        flex="1"
        borderRadius="25px"
        backgroundColor="#f0f0f0"
        p={[2, 4, 12]}  // Reduced padding for smaller screens
        opacity={isVisible ? 1 : 0}
        transform={`translateX(${isVisible ? '0' : '-100px'})`}
        transition="all 0.5s ease-out"
      >
        <Text fontSize={["30px", "40px", "60px"]} fontWeight="bold">
          About Us
        </Text>
        <Text fontSize="20px" mt={4}>
          Welcome to ACA, Metro Detroit's leading cardiology group. Our skilled team of professionals, specializing in various areas of cardiology, provides personalized and compassionate care.
        </Text>
        <Text fontSize="20px" mt={4}>
          Our team is dedicated to understanding your needs and providing a tailored treatment plan that aligns with your lifestyle. We extend our passion for heart health beyond our clinic's walls, actively advocating for heart health awareness and educating people on cardiovascular health and prevention.
        </Text>
        <Text fontSize="20px" mt={4}>
          At ACA, we're more than just a cardiology group - we're your partners in heart health. Together, we strive to create a healthier future for all. We look forward to being a part of your journey to better heart health. Trust your heart to ACA.
        </Text>
        </Box>
      <Box
        flex="1"
        opacity={isVisible ? 1 : 0}
        transition="opacity 0.7s ease-out 0.5s"
      >
        <Image
          src={hero4}
          alt="Cardiology Image"
          borderRadius="25px"
          objectFit="cover"
          width="100%"
          height="auto"
        />
      </Box>
    </Flex>
  );
};

export default About;