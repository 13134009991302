import './StepTwo.css';
import CancellationAndNoShowPolicy from '../Assets/pdfs/CANCELLATION AND NO SHOW POLICY.pdf';
import FormCompletionPolicyAndMedicalRecords from '../Assets/pdfs/FORM COMPLETION POLCY AND MEDICAL RECORDS.pdf';
import MedicationInformation from '../Assets/pdfs/MEDICATION INFORMATION.pdf';
import PatientRegistration from '../Assets/pdfs/PATIENT REGISTRATION.pdf';
import PrivacyPractices from '../Assets/pdfs/PRIVACY PRACTICES.pdf';
import ReleaseMedicalInfo from '../Assets/pdfs/RELEASE MEDICAL INFO.pdf';
import PrivacyPolicyPDF from '../Assets/pdfs/ACA Privacy Policy.pdf';


const StepTwo = () => {
  return (
    <div className="step-two">
      <h2>STEP TWO</h2>
      <p className="text-left">Please take a moment to print and fill out the forms below and bring them with you to your initial visit with us:</p>
      <ul>
        <li><a className="pdf-link" href={PatientRegistration} target="_blank" rel="noopener noreferrer">Patient Registration</a></li>
        <li><a className="pdf-link" href={FormCompletionPolicyAndMedicalRecords} target="_blank" rel="noopener noreferrer">Form Completion Policy And Medical Records</a></li>
        <li><a className="pdf-link" href={MedicationInformation} target="_blank" rel="noopener noreferrer">Medication Information</a></li>
        <li><a className="pdf-link" href={CancellationAndNoShowPolicy} target="_blank" rel="noopener noreferrer">Cancellation And No Show Policy</a></li>
        <li><a className="pdf-link" href={ReleaseMedicalInfo} target="_blank" rel="noopener noreferrer">Release Medical Info</a></li>
        <li><a className="pdf-link" href={PrivacyPractices} target="_blank" rel="noopener noreferrer">Privacy Practices</a></li>
        <li><a className="pdf-link" href={PrivacyPolicyPDF} target="_blank" rel="noopener noreferrer">Privacy of HIPAA Acknowledgement</a></li>
      </ul>
      <h2>STEP THREE</h2>
      <p className="text-left">Give us a call to setup an appointment or come vist us at one of our locations!</p>
    </div>
  );
};

export default StepTwo;
